<template>
  <a-modal
    width="500px"
    v-model="isShow"
    title="厅房费用"
    :mask-closable="false"
    :body-style="{ paddingLeft: 20 }"
  >
    <template slot="footer">
      <a-button class="ant-btn-primary" @click="closeModal">
        关闭
      </a-button>
    </template>
    <a-descriptions>
      <a-descriptions-item label="使用费用">
        {{ order.hall_usage_fee }}元
      </a-descriptions-item>
      <a-descriptions-item v-if="order.is_disinfect === true && order.package_included_disinfection === false" label="消毒费">
        {{ order.disinfection_fee }}元
      </a-descriptions-item>
      <a-descriptions-item v-else></a-descriptions-item>
      <a-descriptions-item v-if="order.is_buddhist_venue === true && order.package_included_buddhist_venue === false" label="佛事场地费">
        {{ order.buddhist_venue_fee }}元
      </a-descriptions-item>
      <a-descriptions-item v-else></a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>

<script>

export default {
  name: 'ShowHallFee',
  props: {
    order: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  components: {},
  data() {
    return {
      data: [],
      loading: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    closeModal() {
      this.isShow = false
    }
  }
}
</script>
